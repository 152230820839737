import * as Sentry from '@sentry/vue';
import type { ComponentPublicInstance } from 'vue';
import {
  formatComponentName,
  generateComponentTrace,
} from '#root/src/utils/handleErrors/components';
import { addExceptionMechanism } from '@sentry/utils';

interface IClientAxiosErrorsHandlerParams {
  error: any;
  place?: string;
  metadata?: IMetadata;
}

interface ICaptureExceptionParams {
  error: any;
  level?: 'info';
  url?: string;
  status?: number;
  place?: string;
  isAxios?: boolean;
  metadata?: IMetadata;
}

type IMetadata = Record<string, string>;

const setIsAxiosTag = (scope: Sentry.Scope, isAxios: boolean) => {
  if (isAxios) {
    scope.setTag('isAxios', true);
  }
};

export const setInfoTag = (scope: Sentry.Scope, lifecycleHook?: string) => {
  if (lifecycleHook) {
    scope.setTag('lifecycleHook', lifecycleHook);
  }
};

const setRequestUrlTag = (scope: Sentry.Scope, url?: string) => {
  if (url) {
    scope.setTag('requestUrl', url);
  }
};

const setAxiosStatusTag = (scope: Sentry.Scope, status?: number) => {
  if (status) {
    scope.setTag('axiosStatus', status);
  }
};

const setComponentNameTag = (scope: Sentry.Scope, name?: string) => {
  if (name) {
    scope.setTag('componentName', name);
  }
};

export const setPlaceTag = (scope: Sentry.Scope, place?: string) => {
  if (place) {
    scope.setTag('place', place);
  }
};

const setLevel = (scope: Sentry.Scope, level?: Sentry.SeverityLevel) => {
  if (level) {
    scope.setLevel(level);
  }
};

const setMetadata = (scope: Sentry.Scope, metadata?: IMetadata) => {
  if (metadata) {
    scope.setContext('vue', metadata);
  }
};

export const captureClientException = ({
  error,
  level,
  url,
  status,
  place,
  isAxios = false,
  metadata,
}: ICaptureExceptionParams) => {
  // Capture exception in the next event loop, to make sure that all breadcrumbs are recorded in time.
  setTimeout(() => {
    Sentry?.withScope((scope: Sentry.Scope) => {
      setLevel(scope, level);
      setMetadata(scope, metadata);

      setIsAxiosTag(scope, isAxios);
      setInfoTag(scope, metadata?.lifecycleHook);
      setRequestUrlTag(scope, url);
      setAxiosStatusTag(scope, status);
      setComponentNameTag(scope, metadata?.componentName);
      setPlaceTag(scope, place);

      scope.addEventProcessor((event) => {
        addExceptionMechanism(event, {
          handled: false,
        });
        return event;
      });

      Sentry?.captureException(error);
    });
  });
};

export const clientAxiosErrorsHandler = ({
  error,
  place,
  metadata,
}: IClientAxiosErrorsHandlerParams) => {
  const captureParams: ICaptureExceptionParams = {
    error,
    isAxios: true,
    place,
    metadata,
  };

  if (error.response) {
    captureParams.url = error.response.config.url;

    captureParams.status = error?.response?.status;

    if (captureParams.status && captureParams.status < 500) {
      captureParams.level = 'info';
    }
  }

  captureClientException(captureParams);
  return true;
};

export const globalVueErrorsHandler = (
  error: any,
  vm?: ComponentPublicInstance | null,
  lifecycleHook = '',
) => {
  if (!import.meta.env.SSR) {
    const componentName = formatComponentName(vm);

    const trace = vm ? generateComponentTrace(vm) : '';
    const metadata: IMetadata = {
      componentName,
      lifecycleHook,
      trace,
    };

    if (error?.name && error.name === 'AxiosError') {
      clientAxiosErrorsHandler({ error, metadata });
      return true;
    }

    captureClientException({
      error,
      metadata,
    });
  }
};
